.externalChat {
    position: absolute;
    z-index: 5;
    width: 360px;
    bottom: 80px;
    right: 16px;
    border: none;
    border-radius: 16px;
    transition: opacity ease-in-out .15s, transform ease-in-out .4s;

    --mainBgColor: #fff;
    --mainTextColor: #333;
    --textGrayColor: rgba(0, 0, 0, .35);
    --iconLight: rgba(0, 0, 0, .37);
    --roomsBorderColor: #fff;
    --textSelectedColor: rgba(25, 118, 210, .8);
    --boardHeaderBg: linear-gradient(112deg, rgba(211, 240, 250, 1) 3%, rgba(212, 245, 200, 1) 100%);
    --bodyBgColor: rgba(0, 0, 0, .06);
    --msgBg: #D4F5C8;
    --myMsgBg: #D3F0FA;
    --msgTextColor: #0F1217;
    --msgTail: no-repeat  url(/tail.svg);
    --myMsgTail: no-repeat  url(/tail-my.svg);
    --scrollbarThumbBorderColor: #fff;
    --sendBtnBg: rgb(25, 118, 210);
    --sendInputBg: #fff;
    --emojiOpenedIcon: rgba(25, 118, 210, .7);
    --emojiBg: #fff;
    --msgDateWrapperBg: #eee;
    --msgDateText: rgba(0, 0, 0, 0.87);
}

@media screen and (max-width: 600px) {
    .externalChat {
        bottom: 124px;
        right: -50px;
    }
}

.externalChat-unread {
    position: absolute;
    height: 12px;
    width: 12px;
    background: rgba(211, 240, 250, 1);
    top: 0px;
    right: 0px;
    border-radius: 8px;
}
