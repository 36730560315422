.video-content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.video-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 100%;
    margin: 20px;
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.video-container.audio-only {
    border: 1px solid #437D6A;
    background-image: url(/microphone-white.svg);
}


.video-container.audio-only.local {
    border: 1px solid #D8A87D;
}

.muted {
    height: 16px;
    width: 16px;
    background-size: contain;
    background-image: url(/microphone-muted.svg);
}

.video-space {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* padding-bottom: 56.25%; задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    overflow: hidden;
}

.video-space.muted {
    background-image: url(/microphone-muted.svg);
}

video {
    max-width: 100%;
    height: 100%;
    border-width: 0;
    outline-width: 0;
    object-fit: cover;
    /* border-radius: 7px; */
}

.participant-name {
    position: absolute;
    top: 5%;
    left: 50%;
    color: #ffffff;
}

.disconnect-participant {
    position: absolute;
    right: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 10;
}

.user-control {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: rgba(25, 118, 210, .5);
}

.user-control:hover~.user-control-menu-container {
    visibility: visible;
}

.user-control-menu-container:hover {
    visibility: visible;
}

.user-control-menu-container {
    width: 220px;
    height: auto;
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #1976d2;
    z-index: 20;
    border-radius: 5px;
    visibility: hidden;
}

.user-control-menu-container .menu-item {
    font-size: 14px;
    padding: 1em;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: #fff;
}

.user-control-menu-container .menu-item:hover {
    background-color: #1976F0;
    border-radius: 5px;
}

.active-speaker {
    box-shadow: 0 0 5px 3px rgba(255, 255, 255, 0.28) !important;
    /* border: 4px solid blue !important; */
}

video::-webkit-media-controls {
    display: none !important;
}

.video-container-background {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    border-radius: 7px;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.avatar-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    font-size: 15px;
}

.avatar-image img {
    border-radius: 50%;
    background-color: #474747;
}

.video-container-avatar {
    max-width: 100%;
    max-height: 80%;
}

.video-container-avatar-only {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    border-radius: 4px;
    /*width: 100%;*/
    /*height: 100%;*/
}

.video-userinfo-container {
    display: flex;
    align-items: center;
    position: absolute;
    left: 4px;
    bottom: 4px;
    padding: 4px 6px;
    background-color: rgba(67, 125, 106, .6);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-family: Gilroy-Medium;
    /* z-index: 99; */
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.video-userinfo-container.audio-only {
    width: 100%;
    left: auto;
    justify-content: center;
    border-top-right-radius: 4px;
}

.video-userinfo-container.local {
    background-color: rgba(216, 168, 125, .6);
}

.hover-background {
    background-color: rgba(0, 0, 0, 0.6)!important;
    opacity: 0;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    pointer-events: none;
    transition: 0.3s ease;
}

.video-container:hover .hover-background {
    opacity: 1;
    transition: 0.3s ease;
}

.flipVideoX {
    transform: scale(-1, 1);
}

.screen-share-video {
    transform: rotate(0deg);
}

.user-indicator-container {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 22px;
    pointer-events: none;
    z-index: 10;
    width: auto;
    background-color: #13BABF;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    border-radius: 0 5px 0 0;
    padding: 0 5px;
}

.indicator-container {
    display: flex;
    margin-right: 5px;
}

.muted-audio {
    width: 16px;
    height: 16px;
    background-image: url(/microphone-muted.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.muted-video {
    width: 16px;
    height: 16px;
    background-image: url(/camera-stopped.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.muted-audio-container {
    display: none;
    margin: 0 8px 0 0;
}

.muted-video-container {
    display: none;
    margin: 0 8px 0 0;
}

.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.connection-container {
    position: relative;
    font-size: 8px;
    text-align: center;
    line-height: 22px;
    padding: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-sizing: border-box;
    /* z-index: 20; */
    background: rgba(45,201,55, .6);
    color: #fff;
    border: 2px solid #fff;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.connection-image {
    width: 8px;
    height: 8px;
    background-image: url(/connection.svg);
    background-repeat: no-repeat;
    background-size: contain;
}

.network-monitoring-container {
    /*width: 200px;*/
    top: 8px;
    left: 42px;
    z-index: 20;
}

.network-item {
    display: flex;
    padding: 2px 0;
}

.network-value {
    padding-left: 10px;
}

.connection-container:hover~.network-monitoring-container {
    visibility: visible!important;
}
