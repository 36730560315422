.board-root {
    border-radius: 24px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--mainBgColor) !important;
}

.board-actions {
    display: flex;
    box-sizing: border-box;
    min-height: 80px;
    padding: 8px 16px;
    margin-top: auto;
}
